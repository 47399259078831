<template>
  <div class="hl-slick-carousel-wrapper" :class="{'hl-slick-carousel-wrapper__wo-pad': slides.length === 1,}">
    <div >
      <VueSlickCarousel ref="carousel" v-bind="settings">
        <div v-for="e in slides" :key="e.id">
          <div class="carousel-item">
            <div class="carousel-item-description">
              <div class="carousel-item-description-title">
                {{ getTranslateFromItem(e.translation, 'title') }}
              </div>
              <div class="carousel-item-description-text">
                {{ getTranslateFromItem(e.translation, 'description') }}
              </div>
              <router-link v-if="!e.generic.demo_call_to_action && e.generic.link && e.generic.link.startsWith('/')" :to="`/${lang}${e.generic.link}`">
                <BaseButton filled>
                  {{getTranslate('homeSolutionsButtonText')}}
                </BaseButton>
              </router-link>
              <a v-if="!e.generic.demo_call_to_action && e.generic.link && e.generic.link.startsWith('http')" :href="`${e.generic.link}`" target="_blank" rel="noopener">
                <BaseButton filled>
                  {{getTranslate('homeSolutionsButtonText')}}
                </BaseButton>
              </a>
              <router-link v-if="e.generic.demo_call_to_action" :to="{name: 'Demo',}">
                <BaseButton filled>
                  {{getTranslate('buttonForADemo')}}
                </BaseButton>
              </router-link>
            </div>
            <div
              v-if="e.generic.orientation === 'landscape'"
              class="carousel-item-image-landscape"
              :data-big-image="bigImage"
              :style="{
                background: `url('${e.generic.photo}') 50% 50% no-repeat`,
                backgroundSize: (imageContain ? 'contain' : 'cover') + '!important',
              }"
            />
            <div
              v-else
              class="carousel-item-image-portrait"
              :data-big-image="bigImage"
              :style="{
                background: `url('${e.generic.photo}') 50% 50% no-repeat`,
                backgroundSize: (imageContain ? 'contain' : 'cover') + '!important',
              }"
            />
          </div>
        </div>
        <template #prevArrow="arrowOption">
          <div class="hl-carousel-big-arrow hl-carousel-big-arrow-prev">
            <img src="@/assets/icons/chevron-left.svg" alt="">
          </div>
        </template>
        <template #nextArrow="arrowOption">
          <div class="hl-carousel-big-arrow hl-carousel-big-arrow-next">
            <img src="@/assets/icons/chevron-right.svg" alt="">
          </div>
        </template>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import BaseButton from "@/components/BaseButton";
import {mapGetters} from "vuex";

export default {
  name: 'CarouselBig',
  props: {
    slides: Array,
    imageContain: {
      type: Boolean,
      default: false
    },
    bigImage: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueSlickCarousel,
    BaseButton
  },
  computed: {
    ...mapGetters([
      'getTranslate',
      'getTranslateFromItem',
      'lang',
      'carousels',
      'trust',
    ]),
  },
  data: () => ({
    settings: {
      arrows: true,
      dots: false,
      infinite: true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: false,
      responsive: [
        {
          breakpoint: 650,
          settings: {
            arrows: false,
            dots: true,
          },
        }
      ],
    },
  }),
}
</script>

<style lang="scss" scoped>
//variables
$arrow-full: 35px;
$arrow-desktop: 35px;
$arrow-tablet: 35px;
$arrow-tablet-v: 35px;
$arrow-mobile: 35px;
$space-for-arrow: 5px;


.hl-carousel-big-arrow {
  opacity: .8;
  width: 35px;
  height: 35px;
  img {
    width: 100%;
  }
}
.hl-carousel-big-arrow:before {
  display: none;
}
.hl-carousel-big-arrow-prev {
  left: -($arrow-full+$space-for-arrow);
}
.hl-carousel-big-arrow-next {
  right: -($arrow-full+$space-for-arrow);
}
.hl-slick-carousel-wrapper {
  padding: 0 $arrow-full+$space-for-arrow;

  @media (max-width: $breakpoint-mobile) {
    padding: 0 0 25px;
  }
}
.hl-slick-carousel-wrapper__wo-pad {
  padding: 0;
}
.carousel-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 0 60px;
  min-height: 430px;
  align-items: stretch;

  @media (max-width: $breakpoint-desktop) {
    padding: 0 30px;

    @media (max-width: $breakpoint-tablet) {
      padding: 0 20px;
      min-height: 380px;

      @media (max-width: $breakpoint-tablet-v) {
        padding: 0 20px;
        min-height: 200px;

        @media (max-width: $breakpoint-mobile) {
          padding: 0 15px;
          min-height: auto;
        }
      }
    }
  }

  &-description {
    flex-grow: 1;
    margin-right: 60px;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: $breakpoint-desktop) {
      margin-right: 50px;

      @media (max-width: $breakpoint-tablet) {
        margin-right: 40px;

        @media (max-width: $breakpoint-tablet-v) {

          @media (max-width: $breakpoint-mobile) {
            margin-right: 0;
            padding: 10px 0;
            align-items: center;
          }
        }
      }
    }

    &-title {
      max-width: 600px;
      font-size: 38px;
      line-height: 56px;
      font-weight: 900;
      transition: .3s;
      color: #25272B;
      margin-bottom: 40px;

      @media (max-width: $breakpoint-desktop) {
        margin-bottom: 25px;
        font-size: 38px;
        line-height: 54px;
        max-width: 440px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 34px;
          line-height: 42px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              margin-bottom: 15px;
              text-align: center;
              max-width: 380px;
            }
          }
        }
      }
    }

    &-text {
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      color: black;
      max-width: 460px;
      margin-bottom: 40px;

      @media (max-width: $breakpoint-desktop) {
        margin-bottom: 25px;

        @media (max-width: $breakpoint-tablet) {

          @media (max-width: $breakpoint-tablet-v) {
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              margin-bottom: 15px;
              text-align: center;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  &-image {
    &-portrait {
      width: 50%;
      max-width: 380px;
      border-radius: 10px;
      min-height: 430px;
      flex-shrink: 0;

      &[data-big-image] {
        width: 60%;
        max-width: 600px;
      }

      @media (max-width: $breakpoint-desktop) {
        max-width: 360px;

        @media (max-width: $breakpoint-tablet) {
          max-width: 320px;
          min-height: 380px;

          @media (max-width: $breakpoint-tablet-v) {
            max-width: 260px;
            min-height: 300px;

            @media (max-width: $breakpoint-mobile) {
              display: none;
            }
          }
        }
      }
    }

    &-landscape {
      width: 50%;
      max-width: 780px;
      border-radius: 10px;
      min-height: 430px;
      flex-shrink: 0;

      &[data-big-image] {
        width: 60%;
        max-width: 600px;
      }

      @media (max-width: $breakpoint-desktop) {
        max-width: 680px;

        @media (max-width: $breakpoint-tablet) {
          max-width: 420px;
          min-height: 380px;

          @media (max-width: $breakpoint-tablet-v) {
            max-width: 360px;
            min-height: 300px;

            @media (max-width: $breakpoint-mobile) {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
